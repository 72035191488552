.download-ctas {
  gap: 12px;
  width: 350px;
  display: flex;
}

.download-ctas > a {
  flex-grow: 1;
  flex-basis: 50%;
  width: 8vw;
}

.download-ctas > a > img {
  object-fit: stretch;
  width: 100%;
  height: 100%;
}

.download-ctas a:hover {
  color: #bbb;
  font-size: 1em;
}

section.hero {
  background-image: linear-gradient(#0003, #0009);
  justify-content: space-between;
  height: 100vh;
  display: flex;
  position: relative;
}

section.hero:before {
  content: "";
  opacity: .3;
  z-index: -2;
  background-image: url("hero.574a01c3.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: 0;
}

.changer-container {
  width: 100%;
  height: 2.8rem;
  position: relative;
  overflow: hidden;
}

.changer-wrapper {
  animation: 16s cubic-bezier(1, 0, 0, 1) infinite move;
  position: relative;
}

.changer-wrapper > .subheading {
  height: 2.6rem;
}

@keyframes move {
  0% {
    top: 0;
  }

  20% {
    top: -2.5rem;
  }

  40% {
    top: -5rem;
  }

  60% {
    top: -7.5rem;
  }

  80% {
    top: -10rem;
  }

  100% {
    top: -12.5rem;
  }
}

.hero {
  align-items: center;
  display: flex;
}

.hero .heading {
  font-size: 5rem;
}

.hero-right {
  justify-content: center;
  align-items: center;
  max-width: 600px;
  max-height: 70%;
  display: flex;
}

.hero-right > img {
  width: 95%;
  height: 95%;
}

@media (width <= 767px) {
  .hero-right > img {
    margin-top: 50px;
  }

  .download-ctas {
    width: 80vw;
    margin: auto;
  }

  .download-ctas > a > img {
    width: 100%;
    height: 100%;
  }
}

@media (width <= 1200px) {
  section {
    flex-direction: column-reverse;
    justify-content: space-around;
  }

  section.hero {
    background-image: linear-gradient(#0003, #0009);
    justify-content: center;
    gap: 0;
    max-height: 100vh;
    margin-top: 0;
  }

  .changer-wrapper > .subheading {
    font-size: 1.5rem;
    overflow: hidden;
  }
}

section.hero {
  box-shadow: 0 20px 60px 20px #000000e6;
}
/*# sourceMappingURL=index.bd591c4a.css.map */
