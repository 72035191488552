.download-ctas {
  display: flex;
  // margin-top: -3.875rem;
  gap: 12px;
  width: 350px;

  & > a {
    flex-basis: 50%;
    flex-grow: 1;
  }
  & > a {
    width: 8vw;
    & > img {
      height: 100%;
      width: 100%;
      object-fit: stretch;
    }
  }
  & a:hover {
    color: #bbb;
    font-size: 1em;
  }
}

section.hero {
  background-image: linear-gradient(rgba(black, 0.2), rgba(black, 0.6));
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: space-between;

  &::before {
    content: "";
    position: absolute;
    background-image: url("../../assets/backgrounds/hero.png");
    opacity: 0.3;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
  }
}

// // .changer-wrapper

.changer-container {
  position: relative;
  height: 2.8rem;
  overflow: hidden;
  width: 100%;
}

.changer-wrapper {
  position: relative;
  animation: move 16s infinite cubic-bezier(1, 0, 0, 1);

  & > .subheading {
    height: 2.6rem;
  }
}

@keyframes move {
  0% {
    top: 0px;
  }
  20% {
    top: -2.5rem;
  }
  40% {
    top: -5rem;
  }
  60% {
    top: -7.5rem;
  }
  80% {
    top: -10rem;
  }
  100% {
    top: -12.5rem;
  }
}

.hero {
  display: flex;
  align-items: center;

  & .heading {
    font-size: 5rem;
  }
}

.hero-right {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 80%;
  max-height: 70%;
  max-width: 600px;
  & > img {
    height: 95%;
    width: 95%;
    // object-fit: cover;
  }
}
@media (max-width: 767px) {
  .hero-right {
    & > img {
      margin-top: 50px;
    }
  }
  .download-ctas {
    width: 80vw;
    margin: auto;
    // margin: 1rem 0px;
    // padding-left: 10px;
    // padding-right: 10px;

    & > a {
      // max-width: 300px;
      // aspect-ratio: 3.46;

      & > img {
        height: 100%;
        width: 100%;
      }
    }
  }
  // .hero-right {
  // 	& > img {
  // 		margin-top: 50px;
  // 	}
  // }
  // .download-ctas {
  // 	& > a {
  // 		// max-width: 300px;
  // 		// aspect-ratio: 3.46;

  // 		& > img {
  // 			height: 90%;
  // 			width: 90%;
  // 			object-fit: stretch;
  // 		}
  // 	}
  // }
}

$breakpoint-custom: 1200px;
@media (max-width: $breakpoint-custom) {
  section {
    flex-direction: column-reverse;
    justify-content: space-around;
  }

  section.hero {
    background-image: linear-gradient(rgba(black, 0.2), rgba(black, 0.6));
    margin-top: 0px;
    justify-content: center;
    gap: 0;
    max-height: 100vh;
  }

  .changer-wrapper > .subheading {
    font-size: 1.5rem;
    overflow: hidden;
  }
}

section.hero {
  box-shadow: 0px 20px 60px 20px rgba(black, 0.9);
}
